<template>
  <b-card title="Ajouter un collaborateur">
    <validation-observer ref="addCollaboratorForm">
      <b-form>
        <b-row>
          <!-- firstname input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Nom du collaborateur'" :type="'text'" :rules="'required'"
                v-model="user.firstname" />
            </b-form-group>
          </b-col>

          <!-- lastname input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Prénom(s) du collaborateur'" :type="'text'" :rules="'required'"
                v-model="user.lastname" />
            </b-form-group>
          </b-col>

          <!-- email input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Email du collaborateur'" :type="'email'" :rules="'required|email'"
                v-model="user.email" :name="'email'" />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- New row -->
        <b-row>
          <!-- phone input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Téléphone du collaborateur'" :type="'number'" v-model="user.telephone" />
            </b-form-group>
          </b-col>

          <!-- adress input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Adresse du collaborateur'" :type="'text'" :rules="'required'"
                v-model="user.address" />
            </b-form-group>
          </b-col>

          <!-- register number input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'N° Matricule du collaborateur'" :type="'number'" :rules="'required'"
                v-model="user.registration_number" />
            </b-form-group>
          </b-col>

          <!-- salary input -->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Salaire du collaborateur'" :type="'number'" v-model="user.salary" />
            </b-form-group>
          </b-col>

          <!--Password Input Field -->
          <b-col md="4">
            <b-form-group>
              <label>Mot de passe</label>
              <validation-provider #default="{ errors }" name="password" rules="required">
                <b-form-input v-model="user.password" type="password" placeholder="........" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--Repeat password must match  -->
          <b-col md="4">
            <b-form-group>
              <label>Confirmer le mot de passe</label>
              <validation-provider #default="{ errors }" rules="required" name="Confirmer le mot de passe">
                <b-form-input v-model="user.confirmPassword" type="password" :state="errors.length > 0 ? false : null"
                  placeholder="........" />
                <small class="text-danger">{{ errors[0] }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- situation matrimonio -->
          <b-col md="4">
            <b-form-group>
              <label>Situation matrimoniale</label>
              <validation-provider #default="{ errors }" name="situation matrimoniale" rules="required">
                <b-form-select v-model="user.marital_status">
                  <b-form-select-option :value="null">Choisir une situation matrimoniale</b-form-select-option>
                  <option value="Célibataire sans enfant">
                    Célibataire sans enfant
                  </option>
                  <option value="Célibataire avec enfant">
                    Célibataire avec enfant
                  </option>
                  <option value="Marié">Marié(e)</option>
                  <option value="Divorcé">Divorcé(e)</option>
                  <option value="Veuf/Veuve">Veuf/Veuve</option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--date d'embauche-->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Année de naissance'" :type="'date'" :rules="'required'"
                v-model="user.birthdate" />
            </b-form-group>
          </b-col>

          <!--Année de naissance-->
          <b-col md="4">
            <b-form-group>
              <ky-input :placeholder="'Date d\'embauche'" :type="'date'" :rules="'required'"
                v-model="user.starting_date" />
            </b-form-group>
          </b-col>

          <!-- work -->
          <b-col md="4">
            <b-form-group>
              <label>Service</label>
              <validation-provider #default="{ errors }" rules="required" name="service">
                <b-form-select v-model="serviceId">
                  <b-form-select-option :value="null">Choisir un service</b-form-select-option>
                  <b-form-select-option :value="service.id" v-for="service in services" :key="service.id">
                    {{ service.name }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="text-right btn-sm">
              <b-link to="/services">Ajouter un service</b-link>
            </div>
          </b-col>

          <!-- function -->
          <b-col md="4">
            <b-form-group>
              <label>Fonction</label>
              <validation-provider #default="{ errors }" rules="required" name="function">
                <b-form-select v-model="user.fonction_id">
                  <b-form-select-option :value="null">Choisir une fonction</b-form-select-option>
                  <b-form-select-option :value="fonction.id" v-for="fonction in fonctions" :key="fonction.length">
                    {{ fonction.name }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- function -->
          <b-col md="4">
            <b-form-group>
              <label>Nombre d'heures à effectué par jour :</label>
              <validation-provider #default="{ errors }" name="time">
                <b-form-input placeholder="Nombre d'heures" type="number" v-model="user.total_hour" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Genre :</label>
              <validation-provider #default="{ errors }" name="genre" rules="required">
                <b-form-select v-model="user.gender">
                  <b-form-select-option :value="null">Genre</b-form-select-option>
                  <b-form-select-option value="M">Masculin</b-form-select-option>
                  <b-form-select-option value="F">Feminin</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Role :</label>
              <validation-provider #default="{ errors }" name="role" rules="required">
                <b-form-select v-model="user.role_id">
                  <b-form-select-option :value="null">Attribuer un role</b-form-select-option>
                  <b-form-select-option value="1">Administrateur</b-form-select-option>
                  <b-form-select-option value="2">Utilisateur standard</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <!-- check -->
        <b-col md="4">
          <b-form-group>
            <validation-provider #default="{ errors }" name="checkbox">
              <b-checkbox value="1" v-model="user.is_supervisor">Ce collaborateur est un superviseur</b-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <div class="alert alert-danger p-1" v-if="errors">
          <span class="text-danger text-sm" v-text="errors"></span>
        </div> -->
        <!-- submit button -->
        <b-col cols="12">
          <div v-show="showError" class="alert alert-danger p-1">
            <span> {{ errors.message }} </span>
          </div>
          <b-button v-b-modal.modal-1 variant="primary" type="submit" @click.prevent="addCollaborators"
            :disabled="isLoading">
            <b-spinner small variant="light" v-if="isLoading == true" type="grow" label="Spinning"></b-spinner>
            Valider
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    KyInput,
    ToastificationContent,
  },
  data() {
    return {
      user: {
        role_id: null,
        fonction: null,
        gender: null,
        marital_status: null,
        total_hour: 9,
        is_supervisor: false,
      },
      userDefault: {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
        address: "",
        is_supervisor: false,
        password: "",
        confirmPassword: "",
        birthdate: "",
        marital_status: null,
        registration_number: "",
        gender: null,
        role_id: null,
      },
      passwordValue: "",
      passwordCon: "",
      service: {},
      fonction: {},
      errorMessage: "",
      error: "",
      errors: "",
      errorModal: false,
      isLoading: false,

      serviceId: null,
      choose: null,
      showError: false,
    };
  },
  computed: {
    ...mapState("enterprise", ["services", "fonctions", "employees"]),

    isCheckPassword() {
      return this.user.password == this.user.confirmPassword;
    },
  },
  methods: {
    async addCollaborators() {
      const valid = await this.$refs.addCollaboratorForm.validate();
      if (!valid || !this.isCheckPassword) return;
      this.isLoading = true;
      this.enterpriseUser(this.$formData(this.user))
        .then((r) => {
          this.isLoading = false;
          this.showError = false;
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Collaborateur ajouté avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.showError = true;
          this.isLoading = false;
          this.errorModal = true;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.errors = error.response.data;
        });
    },

    clearForm() {
      this.user = this.userDefault;
      this.$nextTick().then((response) => {
        this.$refs.addCollaboratorForm.reset();
      });
    },

    ...mapActions("enterprise", [
      "fetchServices",
      "fetchFonctions",
      "enterpriseUser",
    ]),
  },
  mounted() {
    this.fetchServices();
  },

  watch: {
    serviceId() {
      this.fetchFonctions(this.serviceId);
    },
  },
};
</script>
