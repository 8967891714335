<template>
  <div>
    <!-- Second row -->
    <b-tabs v-model="tab" :key="key">
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Liste des Collaborateurs</span>
        </template>

        <b-card-text>
          <user-list-table :items="employees" />
          <!--TableTacheCours -->
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UserXIcon" />
          <span>Liste des Collaborateurs sortants </span>
        </template>

        <b-card-text>
          <out-user-list-table :items="outEmployees" />
          <!--TableTacheCours -->
        </b-card-text>
      </b-tab>

      <b-tab v-if="userInfo.role_id == 1">
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Ajouter un collaborateur</span>
        </template>

        <b-card-text>
          <add-collaborator-form />
          <!--TableTacheCours -->
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { BTabs, BTab } from "bootstrap-vue";
import { BRow, BCol } from "bootstrap-vue";
import UserListTable from "./UserListTable.vue";
import OutUserListTable from "./OutUserListTable.vue";
import AddCollaboratorForm from "./AddCollaboratorForm.vue";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      tab: 0,
      data: {},
      mainProps: {
        blank: true,
        width: 300,
        height: 75,
        class: "m2",
      },
      key: 0,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,

    UserListTable,
    AddCollaboratorForm,
    OutUserListTable,
  },

  mounted() {
    if (this.$route.query.from == "project") {
      this.tab = 2;
    }

    this.getEmployees().then((r) => {});
    this.getOutEmployees().then((r) => {});
  },

  methods: {
    ...mapActions("enterprise", ["getEmployees", "getOutEmployees"]),
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("enterprise", ["employees", "outEmployees"]),
  },
};
</script>

<style>
.add-story-card {
  width: 71%;
  position: absolute;
  bottom: 10px;
  background: white;
  height: 50px;
  margin-bottom: 5px;
}

.add-story-btn {
  margin-top: -24px;
  margin-left: 30px;
  border: 3px solid white;
}

.avatar {
  margin-left: 10px;
}

.employees-card {
  margin-right: 9% !important;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
