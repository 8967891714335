<template>
  <b-card>
    <b-card-body style="padding: 0rem">
      <div>
        <div>
          <div>
            <h4>
              Collaborateurs partis <span>( {{ items.length }} )</span>
            </h4>
            <br />
          </div>
          <div class="row ml-1 mb-2">
            <b-form-group
              label-cols-sm="0"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-2 ml-auto mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filterTable"
                  type="search"
                  placeholder="Rechercher..."
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- Table beginning -->
    <b-table
      no-border-collapse
      ref="refInvoiceListTable"
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredItems"
      :fields="userItems"
      show-empty
      empty-text="Pas de collaborateurs disponible"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <!-- Column: Id -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
            
              class="badge-minimal"
              size="32"
              :src="data.item.picture"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.firstname + " " + data.item.lastname }}
          </span>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>
      <template #cell(progress)="data">
        <b-progress
          :value="data.value.value"
          max="100"
          height="7px"
          :variant="data.value.variant"
          striped
          class="alert-info shadow-sm rounded"
        />
      </template>

      <template #cell(superviseur)="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>

      <template v-if="userInfo.role_id == 1" #cell(action)="data">
        <b-row>
          <b-col class="col-md-3">
            <!-- <b-link
              :to="{
                name: 'chat',
              }"
            >
              <feather-icon icon="MessageCircleIcon" size="20" />
            </b-link> -->
          </b-col>

          <b-col>
            <b-link
              :to="{
                name: 'admin-member-profile-id',
                params: { id: data.item.id },
              }"
              class="font-weight-bold"
            >
              <feather-icon icon="UserIcon" size="20" />
            </b-link>
          </b-col>
        </b-row>
      </template>
      <template #cell(picture)="data">
        <b-avatar :src="data.item.picture"> </b-avatar>
      </template>

      <template #cell(Note)="data">
        <b-form-rating
          disabled
          v-model="data.value.note"
          no-border
          class="text-warning"
          style="background-color: white"
        ></b-form-rating>
      </template>

      <template #cell="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>

      <template #cell(username)="data">
        <span>{{ data.item.firstname }} {{ data.item.lastname }}</span>
      </template>
      <template #cell(is_supervisor)="data">
        <b-badge
          pill
          :variant="'danger'"
          v-if="data.item.is_supervisor == false"
        >
          Non
        </b-badge>
        <b-badge
          pill
          :variant="'success'"
          v-if="data.item.is_supervisor == true"
        >
          Oui
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Affichage par page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="this.filteredItems.length"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BProgress } from "bootstrap-vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  props: ["items"],
  components: {
    BCard,
    BProgress,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      rows: 0,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTable: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      userItems: [
        { key: "name", label: "Collaborateurs", sortable: true },
        { key: "note", label: "Note", sortable: true },
        { key: "fonction.name", label: "Fonction", sortable: true },
        { key: "is_supervisor", label: "Superviseur", sortable: true },
        { key: "action", label: "Action" },
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.userItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("auth", ["userInfo"]),

    filteredItems() {
      return (
        this.items?.filter((item) => {
          return (
            item?.firstname
              .toLowerCase()
              .includes(this.filterTable.toLowerCase()) ||
            item?.lastname
              .toLowerCase()
              .includes(this.filterTable.toLowerCase())
          );
        }) || this.items
      );
    },
  },
  mounted() {
    // Set the initial number of items
    //this.getEmployees();
  },

  watch: {},

  methods: {
    onFiltered(items) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = items.length;
      this.currentPage = 1;
    },

    rowClass(item, type) {
      const presenceOui = "table-success text-success";
      const presenceNon = "table-danger text-danger";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.online == true) {
        return presenceOui;
      }
      if (item.online == false) {
        return presenceNon;
      }
    },

    ...mapActions("enterprise", ["getOutEmployees"]),
  },
};
</script>
